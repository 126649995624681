.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: #13111a;

  .navBtn {
    padding: 16px;
    border-radius: 0;
    height: auto;
    height: 52px;
    background-color: transparent !important;
    box-shadow: none;
  }
}

.bg {
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 52px);
  overflow: auto;
}

.logo {
  margin-left: 16px;
}

.dropdown {
  ul {
    background-color: #302C3F !important;
    padding: 14px 0 !important;
    border-radius: 20px;
    overflow: hidden;
    margin: 8px 16px !important;
  }
  li {
    border-radius: 0 !important;
    padding: 0 !important;
  }
  a {
    line-height: 46px;
    padding: 0 28px;
  }
  :global(.ant-dropdown-menu, .ant-dropdown-menu-item) {
    background-color: #302C3F;
    font-size: 20px;
    font-weight: 700;
  }
}