.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wc-buttons{
  margin-left: auto !important;
}
.wc-buttons .ant-menu-title-content{
  display: block;
  margin-top: 4px;
  position: absolute;
  right: 0;
}
.layout{
  display: flex;
  height: 100%;
  flex-direction: column;
}
.header-menu{
  width: 100%;
  background: #13111a;
  font-size: 22px;
}
#root{
  position: absolute;
  top: 0;
  /* bottom: 0; */
  left:0;
  right:0;
  min-height: 100vh;
  background-color: #13111a;
}
.expiry-date-row{
  /* display: flex;
  align-items: center; */
  margin-top: 2px;
  margin-bottom: 8px;
  margin-left: 10px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.vflex{
  display: flex;
  flex-direction: column;
}
.trade-details{
  width: 400px;
}
.flex1{
  flex: 1;
}
.margin-b10{
  margin-bottom: 10px;
}
.margin-l10{
  margin-left: 10px;
}
.margin-t20{
  margin-top: 20px;
}
.trade-details{
  border-left: 1px solid #ffffff61;
  padding: 10px;
  padding-left: 30px;
  margin-left: 10px;
}
.trade-btn{
  width: 130px;
}
.trade-list-row{
  cursor: pointer;
  white-space: nowrap;
}

.overflow-auto{
  overflow: auto;
}
.tenor-string{
  width: 150px;
  margin-right: 10px;;
}
.balance-card{
  min-width:200px;
  margin:20px;
}
.trades-collections{
  margin-top: 20px;
  height: max-content;
}

.trades-collections .ant-tabs-content-holder {
  overflow: auto !important;
}
.action-btn{
  margin-left: 5px;
}
.balance-row{
  height: 30px;
}
.balance-label{
  /* color:gray; */
  margin-right: 5px;
  margin-bottom: 0.7em;
}
.grid-cell{
  cursor: pointer;
  /* border: 1px springgreen solid; */
  text-align: center;
  transition: all 0.3s;
}
.grid-cell:hover{
  /* background-color: #ffffff61; */
  font-weight: bold;
}
.mmsg{
  padding: 10px;
}
.error.mmsg{
  color: red;
  
}
.index-item{
  margin-right: 20px;
}
.index-item1{
  margin-right: 5px;
}
.wc-placeholder{
  min-height: 600px;
}
.form-tooltip{
  margin-left: 5px;
}
.pointer{
  cursor: pointer;
}
.pointer:hover{
  color: #1890ff;
}